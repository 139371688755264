
























































































































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { VSelectOption } from '@/inc/types'

export default defineComponent({
  name: 'v-select',
  components: {},
  props: {
    options: {
      type: Array as () => VSelectOption[],
      default: [],
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
    },
    label: String,
    name: String,
    required: Boolean,
  },

  setup(props, ctx) {
    const isOpen = ref(false)
    const firstOption = ref(props.placeholder || props.options[0].label)
    const selectedOption = ref('')
    const path = ref()

    const toggle = () => (isOpen.value = !isOpen.value)
    const close = () => (isOpen.value = false)
    const onClick = option => {
      firstOption.value = option.label
      selectedOption.value = option.value
      toggle()
      ctx.emit('input', option.value)
    }

    onMounted(() => {
      path.value = ctx.root.$route.path

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      firstOption.value = props.options.find(o => o?.url === path.value)!.label
    })

    return {
      close,
      firstOption,
      isOpen,
      onClick,
      selectedOption,
      toggle,
    }
  },
})
