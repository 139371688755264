




























import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import BlogArticles from '@/components/blog/Articles.vue'
import BlogList from '@/components/blog/List.vue'

export default defineComponent({
  name: 'Vblog',
  data() {
    return {
      // prettier-ignore
      /* eslint-disable */
      /* eslint-enable */
    }
  },
  components: {
    BlogArticles,
    BlogList,
  },
  setup() {
    return {
      ...useGetters(['content', 'template', 'chrome']),
    }
  },
})
