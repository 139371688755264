






















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import ProfilesList from '@/components/profiles/List.vue'

export default defineComponent({
  name: 'VProfiles',
  data() {
    return {
      // prettier-ignore
      /* eslint-disable */
      /* eslint-enable */
    }
  },
  components: {
    ProfilesList,
  },
  setup() {
    return {
      ...useGetters(['content', 'template', 'chrome']),
    }
  },
})
