












































































































import VSelect from '@/components/form/VSelect.vue'
import axios, { AxiosResponse } from 'axios'
import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import { parseRoute } from '@/inc/utils'
import { ProfilesList } from '@/inc/types'
import ProfilesItem from './Item.vue'

export default defineComponent({
  name: 'profiless-list',
  props: {
    content: {
      type: Object as () => ProfilesList,
      default: {} as ProfilesList,
    },
  },
  components: {
    ProfilesItem,
    VSelect,
  },

  setup(props, ctx) {
    const { api } = parseRoute(ctx.root.$route)
    const job = ref('')
    const items = ref(props.content.items || props.content.items)
    const filterOptions = computed(() =>
      // REVIEW: Interdit de muter (sort()) une prop.
      [...props.content.available]
        .sort()
        .map(option => ({ label: option, value: option }))
    )
    const hasQuery = () => {
      const { query } = ctx.root.$route
      let r = false

      Object.keys(query).forEach(k => {
        if (query[k] !== '') {
          r = true
        }
      })

      return r
    }

    onMounted(() => {
      if (hasQuery()) {
        axios
          // REVIEW: Property 'filter' does not exist on type 'Route'.
          // .get(`${api}/profiles?filter[0]=${ctx.root.$route.filter}`)
          .get(`${api}/profiles?filter=${ctx.root.$route.query.filter}`)
          .then(res => {
            console.log('res', res.data)
            if (res.data) {
              items.value = res.data
            }
          })
      }
    })

    const onChange = async () => {
      const res: AxiosResponse = await axios.get(
        `${api}/profiles?filter=${job.value}`
      )

      if (res.data) {
        console.log('res.data', res.data)
        items.value = res.data
      }
    }

    return {
      contentFormated: props.content,
      filterOptions,
      items,
      job,
      onChange,
      // PreFilter,
    }
  },
})
